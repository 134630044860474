<template>
  <div :class="isBanner == 'recommend' ? 'good-banner-detail-container' : 'good-detail-container'">
    <!-- 商品展示（图片、视频、音频） -->
    <div
      class="good-detail-head"
      v-if="isBanner === 'buy'"
    >
      <div
        class="row-center"
        :style="'width:100%'"
      >
        <!-- <img
          :src="goodDetail.storeInfo.image"
          style="border-radius: 8px;"
          :style="aspectRatio ? 'width: 100%;height: auto;' : 'width: auto;height: 100%;'"
        /> -->
        <goods-item
          :goodsType="goodDetail.storeInfo.cateId ? mediaType[goodDetail.storeInfo.cateId] : ''"
          :goodsSrc="goodDetail.storeInfo.image"
          :goodsPoster="goodDetail.storeInfo.coverImg"
          :goodsSource="goodDetail.storeInfo.detailImg"
          :goodsBackground="goodDetail.storeInfo.backgroundImg"
        ></goods-item>
      </div>
      <!--标题 -->
      <div class="good-detail-title-content row-between">
        <img
          class="good-detail-title-left"
          src="../assets/icon/good-detail-title-left.png"
        />
        <p class="good-detail-name">{{ goodDetail.storeInfo.storeName }}</p>
        <img
          class="good-detail-title-right"
          src="../assets/icon/good-detail-title-right.png"
        />
      </div>

      <!-- 标签 -->
      <div
        style="width: 90vw;margin-left: 5%;flex-wrap: wrap;"
        class="row-center"
      >
        <limit-item
          style="margin-bottom: 12px;"
          v-if="goodDetail.storeInfo.limitInfo"
          limitTitle="限量"
          :limitContent="goodDetail.storeInfo.limitInfo"
        ></limit-item>
        <limit-item
          style="margin-bottom: 12px;"
          v-for="(limitItem,limitIndex) in goodDetail.products[0].customTags"
          v-if="!(!limitItem.field1 && !limitItem.field2) && goodDetail.products[0]"
          :key="limitIndex"
          :limitTitle="limitItem.field2 ? limitItem.field1 : ''"
          :limitContent="limitItem.field2 ? limitItem.field2 : limitItem.field1"
        ></limit-item>
        <img
          style="margin-bottom: 12px;"
          class="good-detail-type"
          :src="mediaType[goodDetail.storeInfo.cateId] == 'img' ? require('../assets/icon/media/good-img.png') : mediaType[goodDetail.storeInfo.cateId] == 'audio' ?  require('../assets/icon/media/good-audio.png'): mediaType[goodDetail.storeInfo.cateId] == 'video' ?  require('../assets/icon/media/good-video.png') :  require('../assets/icon/media/good-3d.png')"
          alt=""
        >
      </div>
      <!-- 积分模块 -->
      <div
        v-if="goodDetail.storeInfo.isDeduct == 1 || goodDetail.storeInfo.isGive == 1 || (couponsProductList.length && goodDetail.storeInfo.isCoupon == 1)"
        @click="isShowIntegral = true"
        class="good-author row-between"
      >
        <div class="row-start">
          <div class="row-start">
            <p
              v-if="goodDetail.storeInfo.isGive == 1"
              class="row-center good-detail-integral"
            >预计得{{ goodDetail.storeInfo.giveIntegral }}积分</p>
            <p
              v-if="goodDetail.storeInfo.isDeduct == 1"
              class="row-center good-detail-integral"
            >积分可抵扣</p>
            <p
              v-if="(couponsProductList.length && goodDetail.storeInfo.isCoupon == 1)"
              class="row-center good-detail-integral"
            >优惠券</p>
          </div>
        </div>
        <img
          class="good-author-next"
          src="../assets/icon/next.png"
        />
      </div>
      <!-- lock -->
      <div class="good-detsil-lock">
        <div class="lock-line"></div>
        <div class="good-detsil-lock-tip row-center">
          <img :src="goodDetail.storeInfo.skuType == 1 ? require('../assets/icon/order/send-order.png') : require('../assets/icon/lock.png')" />
          <p>{{ goodDetail.storeInfo.skuType == 1 ? '未拆盲盒可转赠' : '购买即可体验内容' }}</p>
        </div>
      </div>

      <!-- 作者 -->
      <div
        @click="goAuthor()"
        class="good-author row-between"
      >
        <div class="row-start">
          <img
            class="good-author-pic"
            :src="goodDetail.storeInfo.userPic"
          />
          <div class="col-start">
            <p class="good-detail-author-name">{{ goodDetail.storeInfo.userName }}</p>
            <p class="good-detail-author-tip">艺术家</p>
          </div>
        </div>
        <img
          v-if="goodDetail.storeInfo.producerIsShow == 1"
          class="good-author-next"
          src="../assets/icon/next.png"
        />
      </div>
      <!-- 抽签购 -->
      <lottery
        v-if="goodDetail.storeInfo.exclusiveBuyType == 4 && goodDetail.drawlotsConfig"
        :lotteryInfo="lotteryInfo"
        @drawlotsApply="drawlotsApplyNow"
      />

      <!-- 盲盒藏品配置 -->
      <div
        v-if="goodDetail.storeInfo.skuType == '1'"
        class="blind-box-container"
      >
        <div
          class="row-between"
          style="margin-bottom: 11px;"
        >
          <p class="blind-box-title">藏品配置</p>
          <p class="blind-box-tip">每个盲盒可能抽中以下藏品中的1个</p>
        </div>
        <div
          class="row-start blind-box-item"
          style="margin-top: 8px;"
          v-for="(item,index) in goodDetail.storeInfo.blindBoxItems"
          :key="index"
        >
          <div :style="'width: 60px;height: 60px;margin:10px;background-image: url('+ item.showImage +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% center;background-size: 100% auto;background-color: transparent;'"></div>
          <div class="col-start">
            <p class="blind-box-item-title">{{ item.showName }}</p>
            <p class="blind-box-item-rate">概率：{{ item.rate }}%</p>
          </div>
        </div>
      </div>
      <!-- 金额 -->
      <p class="good-prise good-detail-bottom-prise">
        <span style="font-size: 22px;margin-right:0.3125rem;">¥</span>
        <span style="font-size: 22px;">{{ String(goodDetail.storeInfo.price).split('.')[0].trim()}}</span>
        <span
          v-if="String(goodDetail.storeInfo.price).split('.').length == 2"
          style="font-size: 22px;"
        >.{{String(goodDetail.storeInfo.price).split('.')[1]}}</span>
      </p>
      <div
        v-if="goodStatus=='buyNow' && hasRight"
        @click="buyGood()"
        class="buy-now row-center good-detail-buy-bottom"
      >立即购买</div>
      <!-- openSoon -->
      <div
        v-if="goodStatus=='openSoon' && hasRight"
        class="open-soon row-center good-detail-buy-bottom"
      >{{ goodDetail.storeInfo.startTime.substring(5,7) +'.'+ goodDetail.storeInfo.startTime.substring(8,goodDetail.storeInfo.startTime.length - 3)  }} 开放购买</div>
      <van-count-down
        v-if="goodStatus=='openSoon'"
        class="open-soon-tip"
        :time="lefttime"
        format="DD 天 HH 时 mm 分 后开放购买"
        @finish="timeFinish"
      />

      <!-- soldOut -->
      <div
        v-if="goodStatus=='soldOut'"
        class="sold-out row-center good-detail-buy-bottom"
      > {{ goodDetail.storeInfo.stock != 0 ? '售卖结束' : '售罄'}}</div>
      <!-- no-limit -->
      <div
        v-if="!hasRight && goodStatus !='soldOut'"
        class="sold-out row-center good-detail-buy-bottom"
      >暂无本藏品购买资格</div>
      <div
        class="row-center"
        id="showtime"
      ></div>
    </div>
    <!-- 藏品详情 -->
    <p
      class="good-detail-title"
      style="text-align:left;width:86%;margin-left:7%;"
      v-if="isBanner == 'recommend'"
    >{{ recommendTitle }}</p>
    <p
      class="good-detail-time"
      style="text-align:left;width:86%;margin-left:7%;"
      v-if="isBanner == 'recommend'"
    >{{ recommendTime }}</p>
    <div class="good-introduce-container">
      <p
        class="good-detail-title"
        v-if="isBanner == 'buy'"
      >藏品介绍</p>
      <div
        :class="isBanner == 'recommend' ? 'good-introduce' : 'good-introduce-detail'"
        v-html="isBanner == 'recommend' ? description : goodDetail.storeInfo.description"
      >
      </div>

    </div>
    <!-- 购买须知 -->
    <div
      v-if="isBanner === 'buy'"
      class="purchase-information"
    >
      <p class="purchase-information-title">购买须知</p>
      <p class="good-detail-introduce-content">Meta彼岸中的数字藏品是虚拟数字商品，而非实物商品。因数字藏品的特殊性，一经购买成功，将不支持退换。数字藏品的知识产权或其他权益属发行方或权利人所有，除另行取得发行方或权利人授权外，您不得将数字藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易或任何非法方式进行使用。</p>
    </div>
    <div
      v-if="isBuyNow"
      @click="closeMask()"
      class="mask"
    >
    </div>
    <!-- 积分购提示 -->
    <van-popup
      v-model="isShowIntegral"
      closeable
      position="bottom"
      :style="(couponsProductList.length && goodDetail.storeInfo.isCoupon == 1) ? 'height: 550px': 'height: 220px'"
      style="background: #262626;position: fixed;bottom: 0px !important;left: 0;"
    >
      <p class="integral-rule-title">优惠</p>
      <div
        v-if="goodDetail.storeInfo.isGive == 1"
        class="good-integral-centent"
      >
        <p
          style="width:50px;margin-right:10px;"
          class="row-center good-detail-integral"
        >返积分</p>
        <p class="integral-rule-tip">购买预计可得{{ goodDetail.storeInfo.giveIntegral }}积分</p>
      </div>
      <div
        v-if="goodDetail.storeInfo.isDeduct == 1"
        class="good-integral-centent"
      >
        <p
          style="width:50px;margin-right:10px;"
          class="row-center good-detail-integral"
        >抵扣</p>
        <div>
          <p class="integral-rule-tip">积分可抵扣部分现金，</p>
          <p class="integral-rule-tip">可在结算时选择积分进行抵扣，</p>
          <p class="integral-rule-tip">具体抵扣金额以结算时为准。</p>
        </div>
      </div>
      <div
        v-if="(couponsProductList.length && goodDetail.storeInfo.isCoupon == 1)"
        class="good-integral-centent"
      >
        <p
          style="width:50px;margin-right:10px;"
          class="row-center good-detail-integral"
        >优惠券</p>
        <p class="integral-rule-tip">拥有以下优惠券，满足条件即可用于当前商品</p>
      </div>
      <div
        class="coupons-list"
        :style="goodDetail.storeInfo.isGive == 1 && goodDetail.storeInfo.isDeduct == 1 && explorerChromeSafari ? 'height: calc(300px - 6rem);' : 
            goodDetail.storeInfo.isGive == 1 && goodDetail.storeInfo.isDeduct == 1 && !explorerChromeSafari ? 'height: 300px;' : 
            goodDetail.storeInfo.isGive == 1 && explorerChromeSafari ? 'height:calc(400px - 6rem);' : 
            goodDetail.storeInfo.isGive == 1 && !explorerChromeSafari ? 'height: 400px;' : 
            goodDetail.storeInfo.isGive == 1 && explorerChromeSafari ? 'height:calc(350px - 6rem);' : 
            goodDetail.storeInfo.isGive == 1 && !explorerChromeSafari ? 'height: 350px;' : 
            explorerChromeSafari ? 'height:calc(450px - 6rem);' : 
            'height: 450px'"
        :class="explorerChromeSafari ? 'coupons-list1': 'coupons-list0'"
        v-if="(couponsProductList.length && goodDetail.storeInfo.isCoupon == 1)"
      >
        <div
          v-for="(item,index) in couponsProductList"
          :key="index"
          :class="item.type == 1 ? 'coupon-item2' : 'coupon-item1'"
          class="coupon-item row-center"
        >
          <div class="coupon-item-info col-start">
            <div class="coupon-item-type row-center">{{item.type == 1 ? '限品满减券' : '通用满减券'}}</div>
            <p class="coupon-item-title">{{ item.title.length >= 30 ? item.title.substring(0,30) : item.title }}</p>
          </div>
          <div class="coupon-price col-center">
            <p class="coupon-price-main">
              <span style="font-size: 18px;font-weight: 400 ;">¥</span>
              {{ item.couponPrice }}
            </p>
            <p class="coupon-price-tip">满{{item.useMinPrice}}元可用</p>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 立即购买 -->
    <van-popup
      v-model="isBuyNow"
      closeable
      class="buy-good"
      position="bottom"
      style="height: 237px;background: #262626;"
      :style="!isAuth ? 'position: fixed;bottom: 0px !important;left: 0;' : 'position: fixed;bottom: 6.25rem;left: 0;'"
    >
      <div class="row-left">
        <div
          class="buy-good-img"
          :style="'width: 100px;height: 100px;border-radius: 0.25rem;margin-left: 1.125rem;background-color: transparent;overflow:hidden'"
        >
          <div class="buy-good-img-inline">
            <img
              :src="goodDetail.storeInfo.image"
              alt=""
            >
          </div>
        </div>
        <div>
          <p class="good-prise">
            <span style="font-size: 1.625rem;margin-right:0.625rem;">¥</span>
            <span style="font-size: 2.1875rem;">{{ String(goodDetail.storeInfo.price).split('.')[0].trim()}}</span>
            <span
              v-if="String(goodDetail.storeInfo.price).split('.').length == 2"
              style="font-size: 1.625rem;"
            >.{{String(goodDetail.storeInfo.price).split('.')[1]}}</span>
          </p>
          <p
            class="good-prise-name"
            style="margin:0 0 0.75rem 1.1875rem;"
          >{{ goodDetail.storeInfo.storeName }}</p>
        </div>
      </div>
      <div
        style="margin: 21px 5% 17px 5%;"
        class="line"
      ></div>
      <div class="row-between">
        <p class="buy-good-acout">数量</p>
        <ul class="count row-center">
          <li>
            <p
              @click="numMinus()"
              class="num-jian"
            >-</p>
          </li>
          <li>
            <div class="input-num row-center">{{ goodAmount }}</div>
          </li>
          <li>
            <p
              @click="numAdd()"
              class="num-jia"
            >+</p>
          </li>
        </ul>
      </div>
      <div
        @click="goOrder()"
        class="buy-now row-center"
      >立即购买</div>
    </van-popup>
    <!-- 账户是否认证弹窗 -->
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="goCertification"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <!-- 账户是否登录弹窗 -->
    <el-dialog
      title="账户未登录"
      :visible.sync="loginVisible"
      @confirm="$router.push('/login')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="loginVisible = false">取 消</el-button>
        <div
          @click="$router.push('/login')"
          class="confirm-button row-center"
        >去登录</div>
      </span>
    </el-dialog>
    <!-- 提示弹窗 -->
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-tip-title"
      >{{ goodBuyTitle }}</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >{{ goodBuyTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import { mapGetters } from "vuex";
import Cookies from 'js-cookie'
import { CountDown,Popup } from 'vant';
import { Button,Dialog } from 'element-ui';
import GoodsItem from '../components/GoodsItem.vue'
import LimitItem from '../components/LimitItem.vue'
import Lottery from '../components/Lottery.vue'
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Popup);

Vue.use(CountDown);
import config from '../config'
export default {
  components: {
    GoodsItem,LimitItem,Lottery
  },
  data () {
    return {
      isShowIntegral: false,
      browerOpenVisible: false,
      goodBuyTitle: '',
      goodBuyTip: '',
      loginVisible: false,
      authVisible: false,
      goodStatus: 'buyNow',
      imageWidth: '',
      imageHeight: '',
      aspectRatio: false,
      goodDetail: {
        storeInfo: {
          image: '',
          price: '0'
        },
        products: [
          {}
        ]
      },
      couponsProductList: [],
      description: '',
      recommendTitle: '',
      explorerChromeSafari: false,
      recommendTime: '',
      goodAmount: 1,
      isBuyNow: false,
      lefttime: null,
      mediaType: config.mediaType,
      exclusiveBuyType: '0',
      hasRight: true,
      buyGoodRight: true,//是否有购买资格
      lotteryInfo: {
        status: '1', // 1未开始 2抽签中 3已报名 4报名截止 5已抽签未中 6已抽签已中
        id: '',
        limitInfo: '1000',
        drawLotsStartTime: '10.1 12:00',
        drawLotsEndTime: '10.5 12:00',
        drawLotsTime: '10.5 12:00',
        sellingTime: '10.5 12:00',
        rule: `<p>规则</p>`
      }
    }
  },
  computed: {
    isBanner () {
      return this.$route.query.isBanner
    },
    ...mapGetters(["isAuth"]),
  },
  mounted () {
    this.checkUserAgent()
    this.imageWidth = this.imageHeight = (document.documentElement.clientWidth - 42) + 'px'
    if (this.isBanner == 'recommend') {
      this.recommendTitle = localStorage.getItem('titleAndTime').split('+/+')[0].substring(1,)
      this.recommendTime = localStorage.getItem('titleAndTime').split('+/+')[1].substring(0,localStorage.getItem('titleAndTime').split('+/+')[1].length - 1)
      this.description = localStorage.getItem('recommendDetail')
      this.$store.commit('HIDE_APPLOADING')
    } else {
      this.$store.commit('SHOW_APPLOADING')
      this.getGoodDetails()
      this.getCouponsProduct()
      if (Cookies.get('Login')) {
        this.getUserInfo() //获取用户实名认证状态
        this.goodsCheck() //检查用户是否拥有购买资格
      }
    }

  },
  methods: {
    // 检查设备，适配
    checkUserAgent () {
      var explorer = navigator.userAgent

      if (/(micromessenger)/i.test(explorer)) {
        // 微信浏览器
        this.explorerChromeSafari = false
      } else if (/(MQQBrowser)/i.test(navigator.userAgent)) {
        // qq
        this.explorerChromeSafari = true
      } else if (/(?:Chrome|CriOS)/.test(explorer)) {
        // 谷歌
        this.explorerChromeSafari = true
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(explorer)) {
        // 除上面3个浏览器外的ios设备
        this.explorerChromeSafari = true
      } else {
        // 其他设备
        this.explorerChromeSafari = false
      }
    },
    // 去艺术家详情
    goAuthor () {
      if (this.goodDetail.storeInfo.producerIsShow == 1) {
        this.$router.push('/artistDetails?uid=' + this.goodDetail.storeInfo.creator)
      }
    },
    // 检查是否有购买资格
    goodsCheck () {
      api
        .get('goods/check/' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            this.exclusiveBuyType = result.data.data.exclusiveBuyType
            this.buyGoodRight = result.data.data.hasRight
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 抽签报名
    drawlotsApplyNow () {
      api
        .post('drawlots/apply',{ id: this.goodDetail.drawlotsConfig.id })
        .then(result => {
          if (result.data.success) {
            this.goodBuyTitle = '报名成功！'
            this.goodBuyTip = '请耐心等待抽签'
            this.browerOpenVisible = true
            this.getLotteryInfo(1,this.goodDetail.drawlotsConfig.id)
          } else {
            this.getLotteryInfo(1,this.goodDetail.drawlotsConfig.id)
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取报名抽签结果
    getLotteryInfo (type,id) {
      api
        .get('drawlots/getStatus?type=' + type + '&id=' + id)
        .then(result => {
          if (result.data.success) {
            if (type == 1) {
              this.lotteryInfo.status = result.data.data.status ? 3 : 2
            } else {
              this.lotteryInfo.status = result.data.data.status ? 6 : 5
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取用户是否实名状态
    getUserInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            if (result.data.data.hasRealname) {
              this.$store.commit('HIDE_ISAUTH')
            } else {
              this.$store.commit('SHOW_ISAUTH')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 判断是否微信登录
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    // 倒计时处理
    showtime () {
      var nowtime = new Date()  //获取当前时间
      var endtime = new Date(this.goodDetail.storeInfo.startTime.replace(/-/g,"/"));  //定义结束时间
      this.lefttime = endtime.getTime() - nowtime.getTime()  //距离结束时间的毫秒数
    },
    // 倒计时结束事件
    timeFinish () {
      this.goodStatus = 'buyNow'
    },
    // 获取商品详情
    getGoodDetails () {
      api
        .get('goods/detail/' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            let img = new Image()
            img.src = result.data.data.storeInfo.image
            this.aspectRatio = (img.width / img.height) <= 1 ? false : true

            this.goodDetail = result.data.data
            // 抽签购组件数据处理
            if (this.goodDetail.storeInfo.exclusiveBuyType == 4) {
              if ((new Date()).getTime() < this.goodDetail.drawlotsConfig.drawLotsStartTime) {
                this.hasRight = true
                this.lotteryInfo.status = 1
              } else if ((new Date()).getTime() < this.goodDetail.drawlotsConfig.drawLotsEndTime) {
                this.hasRight = true
                if (Cookies.get('Login')) {
                  this.getLotteryInfo(1,this.goodDetail.drawlotsConfig.id)
                }

              } else if ((new Date()).getTime() < this.goodDetail.drawlotsConfig.drawLotsTime) {
                this.hasRight = true
                this.lotteryInfo.status = 4
              } else {
                this.hasRight = this.buyGoodRight
                if (this.goodDetail.drawlotsConfig.status == 0) {
                  this.hasRight = true
                  this.lotteryInfo.status = 4
                } else {
                  if (Cookies.get('Login')) {
                    this.getLotteryInfo(2,this.goodDetail.drawlotsConfig.id)
                  } else {
                    this.lotteryInfo.status = 5
                  }
                }


              }
              this.lotteryInfo.drawLotsStartTime = this.$moment(this.goodDetail.drawlotsConfig.drawLotsStartTime).format("MM.DD HH:mm")
              this.lotteryInfo.drawLotsEndTime = this.$moment(this.goodDetail.drawlotsConfig.drawLotsEndTime).format("MM.DD HH:mm")
              this.lotteryInfo.drawLotsTime = this.$moment(this.goodDetail.drawlotsConfig.drawLotsTime).format("MM.DD HH:mm")
              this.lotteryInfo.sellingTime = this.$moment(this.goodDetail.drawlotsConfig.sellingTime).format("MM.DD HH:mm")
              this.lotteryInfo.rule = this.goodDetail.drawlotsConfig.drawLotsRuls
              this.lotteryInfo.id = this.goodDetail.drawlotsConfig.id
              this.lotteryInfo.limitInfo = String(this.goodDetail.drawlotsConfig.drawLotsNum)
            }
            // 购买按钮状态显示
            if (this.goodDetail.storeInfo.isOpen == 1 && (this.goodDetail.storeInfo.sellStatus == '已售罄' || this.goodDetail.storeInfo.stock == 0 || (this.goodDetail.storeInfo.endTime && (new Date(this.goodDetail.storeInfo.endTime.replace(/-/g,"/"))).getTime() < (new Date()).getTime()))) {
              this.goodStatus = 'soldOut'
            } else if (this.goodDetail.storeInfo.isOpen == 1 && (this.goodDetail.storeInfo.sellStatus == '即将开放' || this.goodDetail.storeInfo.sellStatus == '即将开放' && this.goodDetail.storeInfo.startTime)) {
              this.goodStatus = 'openSoon'
              this.showtime()
            } else {
              this.hasRight = this.buyGoodRight
              this.goodStatus = 'buyNow'
            }

          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            window.history.back();
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 获取用户该商品可使用优惠券
    getCouponsProduct () {
      api
        .get('coupons/list?productId=' + this.$route.query.id + '&page=0&limit=65535')
        .then(result => {
          if (result.data.success) {
            console.log(result);
            this.couponsProductList = result.data.data
            // this.couponsProductList = result.data.data
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
            this.isBuyNow = false
          }
        })
    },
    // 点击购买时判断是否登录是否实名认证
    buyGood () {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else {
        if (this.isAuth) {
          this.authVisible = true
        } else {
          this.isBuyNow = true
        }
      }
    },
    // pico端提示
    goCertification () {
      if (Cookies.get('Device') == 'pico') {
        this.authVisible = false
        this.goodBuyTip = '请前往手机端完成实名认证'
        this.browerOpenVisible = true
      } else {
        this.$router.push('/certification')
      }
    },
    closeMask () {
      this.isBuyNow = false
    },
    numAdd () {
      this.goodAmount += 1;
    },
    numMinus () {
      this.goodAmount = this.goodAmount <= 1 ? 1 : this.goodAmount - 1
    },
    // 添加订单
    goOrder () {
      if (this.goodDetail.storeInfo.stock < this.goodAmount) {
        this.$toast.fail('库存不足！')
      } else if (this.goodDetail.storeInfo.singleLimit !== 0 && this.goodDetail.storeInfo.singleLimit < this.goodAmount) {
        this.$toast.fail('超出单笔购买限制！')
      } else if (this.goodDetail.storeInfo.sellStatus == '即将开放' && (new Date(this.goodDetail.storeInfo.endTime.replace(/-/g,"/"))).getTime() < (new Date()).getTime()) {
        this.goodStatus = 'soldOut'
        this.$toast.fail('商品已售罄！')
      } else {
        api
          .post('cart/add',{ "cartNum": this.goodAmount,"productId": this.goodDetail.products[0].productId,"uniqueId": this.goodDetail.products[0].unique })
          .then(result => {
            if (result.data.success) {
              this.$store.commit('SHOW_APPLOADING')
              this.getOrderConfirm(result.data.data.cartId)
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
              this.isBuyNow = false
            }
          })
      }
    },
    // 提交订单
    getOrderConfirm (cartId) {
      api
        .post('order/confirm',{ "cartId": cartId })
        .then(result => {
          if (result.data.success) {
            localStorage.setItem('orderCreate',JSON.stringify({ orderKey: result.data.data.orderKey,payType: 'alipay' }))
            this.orderComputed(result.data.data.orderKey) //金额计算
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 订单金额计算
    orderComputed (orderKey) {
      api
        .post('order/computed/' + orderKey,{ couponId: 0,})
        .then(result => {
          if (result.data.success) {
            if (result.data.status == 'EXTEND_ORDER') {

            } else {
              localStorage.setItem('isDeduct',this.goodDetail.storeInfo.isDeduct)
              localStorage.setItem('deductIntegral',this.goodDetail.storeInfo.deductIntegral)
              localStorage.setItem('integralRate',this.goodDetail.storeInfo.integralRate)
              localStorage.setItem('goodDetailId',this.$route.query.id)

              localStorage.setItem('buyDetailInfo',JSON.stringify(result.data.data.result))
              localStorage.setItem('buyGoodDetail',JSON.stringify(this.goodDetail))
              localStorage.setItem('isCoupon',this.goodDetail.storeInfo.isCoupon)
              // 获取支付方式
              this.getPayChannel()
            }
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取后台配置支付状态
    getPayChannel () {

      api
        .get('common/getPayChannel')
        .then(result => {
          if (result.data.success) {
            if (result.data.data.pay_channel == '0') {
              this.isBuyNow = false
              this.goodBuyTip = '支付通道暂未开放'
              this.browerOpenVisible = true
            } else if (this.isWechat() && result.data.data.pay_channel == '2') {
              this.isBuyNow = false
              this.goodBuyTip = '请在微信以外浏览器内完成支付'
              this.browerOpenVisible = true
            } else {
              localStorage.setItem('buyType',result.data.data.pay_channel)
              setTimeout(() => {
                localStorage.removeItem('userDeductIntegral')
                localStorage.removeItem('userCouponsSelectId')
                localStorage.setItem('buyDetailReset',0)
                this.$store.commit('HIDE_APPLOADING')
                this.$router.push('/buyDetail')
              },1000)
            }
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    }
  },
}
</script>
<style lang="less" scoped>
.good-banner-detail-container {
  margin-top: 3.875rem;
  padding-bottom: 10px;
}
.good-detail-container {
  padding-bottom: 60px;
}
.good-detail-container,
.good-banner-detail-container {
  .good-detail-time {
    width: 100%;
    text-align: center;
    margin-top: 0.125rem;
    font-size: 0.75rem;
    font-family: NotoSansHans-Regular, NotoSansHans;
    font-weight: 400;
    color: #999999;
    line-height: 1.125rem;
  }

  .good-detail-prise {
    margin-right: 1.3125rem;
    font-size: 1.375rem;
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #dc1320;
    line-height: 1.625rem;
  }
  .good-author {
    margin-top: 15px;
    margin-left: 3%;
    width: 94%;
    height: 48px;
    background: #2c2c2c;
    border-radius: 8px;
    .good-author-pic {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin: 0 13px;
    }
    .good-author-next {
      width: 8px;
      height: auto;
      margin-right: 15px;
    }
    .good-detail-author-name {
      font-size: 14px;
      font-weight: 500;
      color: #cccccc;
      line-height: 20px;
    }
    .good-detail-author-tip {
      font-size: 12px;
      font-weight: 500;
      color: #666666;
      line-height: 17px;
    }
  }

  .buy-now {
    height: 2.75rem;
    position: relative;
    right: 0;
    margin: 0 1.3125rem 0 1.1875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    letter-spacing: 2px;
    cursor: pointer;
    background-size: cover;
  }

  .open-soon {
    height: 2.75rem;
    position: relative;
    right: 0;
    background-image: url('../assets/icon/open-soon.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-color: transparent;
    margin: 1.3125rem 1.3125rem 0 1.1875rem;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    cursor: not-allowed;
    pointer-events: none;
    background-size: cover;
  }

  .sold-out {
    height: 2.75rem;
    position: relative;
    right: 0;
    background-image: url('../assets/icon/sold-out.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-color: transparent;
    margin: 1.3125rem 1.3125rem 0 1.1875rem;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    cursor: not-allowed;
    pointer-events: none;
    background-size: cover;
  }

  .open-soon-tip {
    width: 100%;
    display: none;
    text-align: center;
    line-height: 1.375rem;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
  }

  .author-content {
    width: 100%;
    height: 4.125rem;
    background: #ffffff;
    margin: 0.625rem 0;

    .good-detail-author-name {
      margin-left: 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: #333333;
      line-height: 1.375rem;
    }

    .good-detail-p {
      margin-left: 1rem;
      margin-top: 0.4rem;
      font-size: 0.75rem;
      font-weight: 400;
      color: #999999;
      line-height: 1.0625rem;
    }
  }

  .mask {
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
    height: 100%;
    filter: alpha(opacity=60);
    /*设置透明度为60%*/
    opacity: 0.6;
    /*非IE浏览器下设置透明度为60%*/
    z-index: 999;
  }

  .buy-good {
    width: 100%;
    height: 18.5625rem;
    background: #ffffff;
    // border-radius: 16px 16px 0px 0px;
    z-index: 1000;
    padding: 1.5625rem 0 1.125rem 0;
    .buy-good-img {
      display: flex;
      justify-content: center;
      align-items: center;
      &-inline {
        display: block;
        text-align: center;
        width: 100%;
        height: 100%;
        position: relative;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          bottom: 0;
          right: 0;
          transform: translate(-50%, -50%);
        }
      }
    }
    .good-prise {
      font-family: SFUIDisplay-Bold, SFUIDisplay;
      font-weight: bold;
      color: #ffffff;
      line-height: 42px;
      margin-left: 1.25rem;
    }
    .good-prise-name {
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: 500;
      color: #cccccc;
      line-height: 19px;
      margin-left: 1.25rem;
    }
    .buy-good-acout {
      margin-bottom: 1.6875rem;
      font-size: 14px;
      font-weight: 500;
      color: #f0f0f0;
      line-height: 20px;
      margin-left: 5%;
      margin-top: 3px;
    }

    .count {
      margin-bottom: 1.6875rem;
      font-size: 1rem;
      font-weight: 500;
      color: #101010;
      line-height: 1.25rem;
    }

    .input-num {
      width: 40px;
      height: 26px;
      background: #545454;
      border-radius: 4px;
      border: none;
      text-align: center;
      pointer-events: none;
      font-size: 14px;
      font-family: SFUIDisplay-Regular, SFUIDisplay;
      font-weight: 400;
      color: #ffffff;
      line-height: 16px;
    }

    .num-jia,
    .num-jian {
      width: 0.5625rem;
      height: 1rem;
      font-size: 0.875rem;
      font-family: SFUIDisplay-Regular, SFUIDisplay;
      font-weight: 400;
      color: #e4e4e4;
      line-height: 1rem;
    }

    .num-jia {
      margin: 0 1.1875rem 0.1rem 0.625rem;
    }

    .num-jian {
      margin-right: 0.625rem;
    }
  }

  .purchase-information {
    margin-top: 10px;
    width: 94%;
    margin-left: 3%;
    background: #2c2c2c;
    padding: 0 0 1.5625rem 0;
    border-radius: 8px;
    padding-top: 14px;

    .good-detail-introduce-content {
      width: 94%;
      margin-left: 3%;
      font-size: 0.875rem;
      font-family: NotoSansHans-Regular, NotoSansHans;
      font-weight: 400;
      color: #cccccc;
      line-height: 1.5rem;
    }
  }
  .line {
    width: 94%;
    margin-left: 3%;
    height: 1px;
    background: #3c3c3c;
  }
  .purchase-information-title,
  .good-detail-title {
    width: 94%;
    margin-left: 3%;
    text-align: left;
    font-size: 16px;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    letter-spacing: 1px;
  }
}
.good-detail-head {
  width: 100%;
  height: auto;
  background: #1c1c1c;
  position: relative;
  .good-prise {
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    position: absolute;
    bottom: 4.6875rem;
    right: 1.1875rem;
  }
  .good-detail-img {
    width: 100%;
    height: 15rem;
  }
  .good-detail-buy-bottom {
    width: 60%;
    position: fixed;
    margin: 0 1.3125rem 0 1.1875rem !important;
    bottom: 7px;
    right: 0;
    z-index: 1999;
  }
  .good-detail-bottom-prise {
    width: 39%;
    position: fixed;
    margin: 0 1.3125rem 0 1.1875rem !important;
    bottom: 7px;
    left: 0;
    z-index: 1999;
  }
}
.good-detail-line {
  width: 94%;
  margin-left: 3%;
  height: 1px;
  background: #3c3c3c;
}
.good-introduce-container {
  width: 94%;
  margin-left: 3%;
  background: #2c2c2c;
  border-radius: 8px;
  padding-top: 0.875rem;
  margin-top: 10px;
}
.good-detail-type {
  height: 20px;
  width: auto;
}
.good-detsil-lock {
  margin: 33px 0 22px 0;
  position: relative;
  .lock-line {
    width: 94%;
    margin-left: 3%;
    height: 1px;
    background: #3a3a3a;
    z-index: 100;
  }
  .good-detsil-lock-tip {
    position: absolute;
    width: 50vw;
    height: 18px;
    left: 25vw;
    top: -8px;
    background: #1c1c1c;
  }
  img {
    width: 1rem;
    height: auto;
    margin: 0 12px 0 10px;
    z-index: 101;
  }
  p {
    margin-right: 10px;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #cccccc;
    line-height: 19px;
    z-index: 101;
    webkit-text-size-adjust: none;
  }
}
.good-detail-title-content {
  width: 94%;
  margin: 23px 3% 16px 3%;
  .good-detail-name {
    font-size: 1.1875rem;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.6875rem;
    width: 70%;
    text-align: center;
    word-break: break-all;
  }
  img {
    width: 42px;
    height: auto;
  }
}
.good-detail-line {
  width: 94%;
  margin-left: 3%;
  height: 1px;
  background: #3c3c3c;
}
.blind-box-container {
  width: 94%;
  margin-left: 3%;
  padding-bottom: 8px;
  background: #2c2c2c;
  border-radius: 8px;
  padding-top: 0.875rem;
  margin-top: 10px;
  .blind-box-title {
    font-size: 16px;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    letter-spacing: 1px;
    margin-left: 3%;
  }
  .blind-box-tip {
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #666666;
    line-height: 17px;
    margin-right: 3%;
  }
  .blind-box-item {
    width: 96%;
    height: 80px;
    margin-left: 2%;
    background: #222222;
    border-radius: 8px;
    .blind-box-item-title {
      font-size: 16px;
      font-family: lantingheiweight;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      margin-left: 6px;
    }
    .blind-box-item-rate {
      font-size: 12px;
      font-family: lantingheiweight;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
      margin: 10px 0 0 6px;
    }
  }
}
.good-detail-integral {
  width: auto;
  height: 20px;
  background: #ed9f9f;
  color: #501616;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 900;
  margin-left: 13px;
  padding: 0 12px;
}
.integral-rule-title {
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-weight: 800;
  margin-top: 20px;
}
.good-integral-centent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
}
.integral-rule-tip {
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  margin-left: 20px;
  margin-bottom: 10px;
}
.coupons-list {
  width: 100%;
  overflow-y: auto;
}
.coupon-item1 {
  background-image: url('../assets/icon/coupon/coupon1.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.coupon-item2 {
  background-image: url('../assets/icon/coupon/coupon2.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.coupon-item {
  width: 92vw;
  height: 21vw;
  margin: 15px 4vw 0 4vw;
  position: relative;

  .coupon-item-tip {
    width: 86vw;
    min-height: 15vw;
    height: auto;
    margin: 15px 4vw 0 4vw;
    padding: 3vw;
    background: #ffffff;
    border-radius: 10px;
    color: #000000;
    font-size: 13px;
    font-weight: 400;
  }
  .coupon-item-info {
    width: 76.9%;
    height: 21vw;
    .coupon-item-type {
      position: absolute;
      top: 2vw;
      left: 3vw;
      font-size: 12px;
      color: #ffffff;
      width: 19vw;
      height: 5vw;
    }
    .coupon-item-title {
      position: absolute;
      top: 9vw;
      left: 3vw;
      width: 74%;
      height: 12vw;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .coupon-price {
    width: 23.1%;
    height: 21vw;
    .coupon-price-main {
      font-size: 24px;
      color: #ffffff;
      font-weight: bold;
    }
    .coupon-price-tip {
      font-size: 12px;
      color: #ffffff;
      font-weight: 300;
    }
  }
}
.coupons-list1 {
  padding-bottom: 6rem;
}
</style>